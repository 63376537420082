export default function DeleteIcon() {
	return (
		<svg
			className='w-6 h-6 text-black dark:text-white'
			viewBox='0 0 1024 1024'
			version='1.1'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fill='currentColor'
				d='M512 0C229.29408 0 0 229.248 0 512c0 282.76736 229.26336 512 512 512 282.76736 0 512-229.18144 512-512C1024 229.29408 794.76736 0 512 0z m209.90976 654.09024a47.5904 47.5904 0 0 1 14.08512 33.90976 47.95392 47.95392 0 0 1-47.99488 47.99488 47.75936 47.75936 0 0 1-33.90976-14.08512L512 579.89632l-141.99296 142.05952a47.9488 47.9488 0 0 1-34.00704 14.08512c-26.45504 0-47.95904-21.45792-47.95904-48.01536A47.68768 47.68768 0 0 1 302.08 654.15168L444.15488 512 302.1056 369.95584a47.88224 47.88224 0 0 1-14.03904-33.95584 47.99488 47.99488 0 0 1 81.92-33.95584l141.99296 142.07488L654.07488 302.08a47.92832 47.92832 0 0 1 33.90976-14.03904c26.48576 0 47.99488 21.45792 47.99488 47.99488a47.75424 47.75424 0 0 1-14.08512 33.95584l-141.99808 141.99296 142.01344 142.1056z m0 0'></path>
		</svg>
	);
}
