const SettingsIcon = () => {
	return (
		<svg
			className='w-4 h-4 text-black dark:text-white'
			viewBox='0 0 1024 1024'
			version='1.1'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fill='currentColor'
				d='M448.487619 97.52381l130.096762 0.170666c40.399238 0.073143 73.142857 32.792381 73.191619 73.216l0.048762 21.211429a345.283048 345.283048 0 0 1 71.143619 39.960381l17.408-10.044953a73.313524 73.313524 0 0 1 99.961905 26.819048l65.219047 112.566857a73.313524 73.313524 0 0 1-22.893714 97.816381l-3.974095 2.438095-17.481143 10.093715a341.479619 341.479619 0 0 1-1.292191 83.968l12.361143 7.168a73.313524 73.313524 0 0 1 28.867048 96.329142l-2.023619 3.803429-61.098667 105.813333a73.313524 73.313524 0 0 1-96.329143 28.867048l-3.803428-2.048-16.896-9.752381a341.918476 341.918476 0 0 1-68.291048 38.083048l0.024381 29.062095a73.313524 73.313524 0 0 1-68.754286 73.264762l-4.632381 0.146285-130.121142-0.170666a73.313524 73.313524 0 0 1-73.191619-73.216l-0.048762-35.035429a346.599619 346.599619 0 0 1-57.368381-34.035809l-31.158857 17.944381a73.313524 73.313524 0 0 1-99.986286-26.819048l-65.219048-112.566857a73.313524 73.313524 0 0 1 22.918095-97.816381l3.949715-2.438095 31.719619-18.285715c-2.438095-23.161905-2.56-46.665143-0.219429-70.119619l-35.206095-20.333714a73.313524 73.313524 0 0 1-28.891429-96.329143l2.048-3.803428 61.098667-105.813334a73.313524 73.313524 0 0 1 96.329143-28.867047l3.803429 2.048 30.72 17.724952a341.284571 341.284571 0 0 1 64.609523-39.716571l-0.048762-27.89181a73.313524 73.313524 0 0 1 68.754286-73.264762L448.487619 97.52381z m145.798095 283.721142a146.407619 146.407619 0 0 0-200.167619 53.638096 146.773333 146.773333 0 0 0 53.711238 200.362666 146.407619 146.407619 0 0 0 200.167619-53.638095 146.773333 146.773333 0 0 0-53.711238-200.362667z m-136.655238 90.258286a73.118476 73.118476 0 0 1 96.182857-28.842667l3.803429 2.048 3.657143 2.267429a73.508571 73.508571 0 0 1 23.210666 98.011429 73.118476 73.118476 0 0 1-99.961904 26.819047 73.48419 73.48419 0 0 1-26.892191-100.303238z'
				p-id='7992'></path>
		</svg>
	);
};

export default SettingsIcon;
