export default function StartIcon() {
	return (
		<svg
			viewBox='0 0 1024 1024'
			xmlns='http://www.w3.org/2000/svg'
			className='w-4 h-4 text-black dark:text-white'>
			<path
				d='M512 0c-282.752 0-512 229.184-512 512s229.248 512 512 512c282.816 0 512-229.184 512-512s-229.184-512-512-512l0 0zM734.976 545.792l-273.088 199.104c-5.568 4.032-11.904 6.016-18.176 6.016-5.696 0-11.392-1.728-16.512-4.992-10.88-7.04-17.6-20.352-17.6-34.816l0-398.208c0-14.464 6.784-27.776 17.6-34.816 10.816-6.976 24.064-6.656 34.624 1.088l273.088 199.104c9.92 7.232 16 20.032 16 33.728s-6.08 26.496-15.936 33.792l0 0z'
				fill='currentColor'></path>
		</svg>
	);
}
